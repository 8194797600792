<template>
  <div class="comment">
    <div v-if="showlike">
      <van-divider />
      <van-grid :border="false">
        <van-grid-item />
        <van-grid-item
          icon="star-o"
          v-show="isCollect == false"
          @click="CollectHandle()"
          :text="'' + CollentCount"
        />
        <van-grid-item
          icon="star"
          v-show="isCollect == true"
          :text="'' + CollentCount"
        />
        <van-grid-item
          icon="good-job-o"
          v-show="isLike == false"
          @click="LikeHandle()"
          color="red"
          :text="'' + LikeCount"
        />
        <van-grid-item
          icon="good-job"
          v-show="isLike == true"
          color="red"
          :text="'' + LikeCount"
        />
      </van-grid>
    </div>
    <div class="mydivider"></div>
    <div class="comment-list">
      <van-tabs>
        <van-tab title="热门评论">
          <van-list
            @load="onLoad"
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            v-show="comments.length != 0"
          >
            <div
              class="item van-hairline--bottom van-hairline--top"
              v-for="comment in comments"
              :key="comment.id"
            >
              <van-image
                round
                width="41px"
                height="41px"
                fit="fill"
                :src="comment.headImgurl"
                 @click.stop="toUserHomePage(comment.creatorId)"
              />
              <div class="info">
                <p>
                  <span class="name">{{ comment.trueName }}</span>
                  <userIcon :iconstring="comment.icon"></userIcon>
                  <span v-show="comment.hasLike == true" style="float: right">
                    <span
                      class="van-icon van-icon-good-job-o zan"
                      style="color: red"
                    ></span>
                    <span class="count">{{ comment.likeCount }}</span>
                  </span>
                  <span
                    v-show="comment.hasLike == false"
                    style="float: right"
                    @click="LikeComment(comment.id)"
                  >
                    <span class="van-icon van-icon-good-job-o zan"></span>
                    <span class="count">{{ comment.likeCount }}</span>
                  </span>
                </p>
                <p>{{ comment.detailsText }}</p>
                <p>
                  <van-tag
                    v-if="comment.sourceType == 1"
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    >#线下#
                  </van-tag>

                  <span class="time">{{ comment.creationTime | relTime }}</span
                  >&nbsp;
                  <van-tag plain @click="openReply(comment.id)"
                    >{{ comment.replyCount }} 回复</van-tag
                  >
                </p>
                <!--回复详情-->
                <div
                  class="item van-hairline--bottom van-hairline--top"
                  style="background-color: #f9f8f8"
                  v-for="replycomment in comment.replyList"
                  :key="replycomment.id + '2'"
                >
                  <van-image
                    round
                    width="41px"
                    height="41px"
                    fit="fill"
                    :src="replycomment.headImgurl"
                     @click.stop="toUserHomePage(replycomment.creatorId)"
                  />
                  <div class="info">
                    <p>
                      <span class="name">{{ replycomment.trueName }}</span>
                      <userIcon :iconstring="replycomment.icon"></userIcon>
                    </p>
                    <p>{{ replycomment.detailsText }}</p>
                    <p>
                      <span class="time">{{
                        replycomment.creationTime | relTime
                      }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
          <van-empty
            v-show="comments.length == 0"
            description="暂无评论"
            image="/image/empty-image-default.png"
          />
        </van-tab>
        <van-tab title="点赞列表">
          <van-list
            v-model="likeUsersLoading"
            :finished="likeUsers != null"
            @load="getLikeUserList"
            v-show="likeUsers == null || likeUsers.length > 0"
          >
            <van-grid :column-num="6" :border="false" 
             style="max-height: 320px; overflow-y: scroll;">
              <van-grid-item
                v-for="item in likeUsers"
                :key="item.id"
                :text="item.trueName"
              >
                <template #icon>
                  <van-image
                    round
                    width="41px"
                    height="41px"
                    fit="fill"
                    :src="item.headImgurl"
                  />
                </template>
              </van-grid-item>
            </van-grid>
          </van-list>
          <van-empty
            v-show="likeUsers != null && likeUsers.length == 0"
            description="暂无点赞"
            image="/image/empty-image-default.png"
          />
        </van-tab>
        <van-tab title="已读列表">
            <van-list
              v-model="viewUsersLoading"
              :finished="viewUsers != null"
              @load="getViewUsersList"
              v-show="viewUsers == null || viewUsers.length > 0"
            >
              <van-grid :column-num="6" :border="false"
              style="max-height: 320px; overflow-y: scroll;">
                <van-grid-item
                  v-for="item in viewUsers"
                  :key="item.id"
                  :text="item.trueName"
                >
                  <template #icon>
                    <van-image
                      round
                      width="41px"
                      height="41px"
                      fit="fill"
                      :src="item.headImgurl"
                    />
                  </template>
                </van-grid-item>
              </van-grid>
            </van-list>
            <van-empty
              v-show="viewUsers != null && viewUsers.length == 0"
              description="暂无已读"
              image="/image/empty-image-default.png"
            />
        </van-tab>
      </van-tabs>
    </div>
    <div
      class="reply-container"
      ref="commentInput"
      :style="{ backgroundColor: '#e6f9fe', zIndex: showReply ? 3000 : 2000 }"
    >
      <van-field
        v-model.trim="value"
        placeholder="写评论..."
        type="textarea"
        rows="1"
        size="large"
        @input="oninputstring()"
        style="background-color: #e6f9fe"
        autosize
      >
        <van-loading
          v-if="submiting"
          slot="button"
          type="spinner"
          size="16px"
        ></van-loading>
        <van-tag
          round
          v-else
          slot="button"
          size="large"
          color="#9d1d22"
          @click="submit()"
          >提交</van-tag
        >
      </van-field>
    </div>
    <!-- 回复 -->
    <van-action-sheet
      @closed="reply.commentId = null"
      v-model="showReply"
      :round="false"
      class="reply_dialog"
      title="回复评论"
    >
      <van-list
        @load="getReply"
        :immediate-check="false"
        v-model="reply.loading"
        :finished="reply.finished"
        finished-text="没有更多评论了"
      >
        <div
          class="item van-hairline--bottom van-hairline--top"
          v-for="reply in reply.list"
          :key="reply.id.toString()"
        >
          <van-image
            round
            width="41px"
            height="41px"
            fit="fill"
            :src="reply.headImgurl"
          />
          <div class="info">
            <p>
              <span class="name">{{ reply.trueName }}</span>
            </p>
            <p>{{ reply.detailsText }}</p>
            <p>
              <span class="time">{{ reply.creationTime | relTime }}</span>
            </p>
          </div>
        </div>
      </van-list>
    </van-action-sheet>
  </div>

  <!-- 都不输入框 -->
</template>

<script>
import { Toast } from "vant";
import {
  getCommentList,
  AddCommentInfo,
  UpdateCommentLikeCountInfo,
  getItmeLikeList,
  AddLike,
  getCommentLikeUserList,
} from "@/api/commentLike";
import { getBrosingLogUserList } from "@/api/browsingLog";

import UserIcon from "./userIcon";

export default {
  props: {
    showlike: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      scroll: 0,
      docmHeight: document.documentElement.clientHeight, //默认屏幕高度
      showHeight: document.documentElement.clientHeight, //实时屏幕高度
      keyboarPading: "0px",
      hideshow: true, //显示或者隐藏footer
      CommentPage: {},
      //     CommentPage: {
      //     current: 1,
      //     pageSize: 10,
      //     filterData: {
      //        itemId: "",
      //        itemType: 0,
      //     },
      //   },

      // 上拉加载中
      loading: false,
      // 全部加载完毕
      finished: false,
      // 输入的内容
      value: "",
      // 控制提交中状态数据
      submiting: false,
      comments: [],
      offset: null,

      isLike: false,
      LikeCount: 0,
      isCollect: false,
      CollentCount: 0,
      ReplyPage: {
        current: 1,
        pageSize: 10,
        filterData: {
          itemId: "",
          itemType: 6,
        },
      },
      showReply: false,
      reply: {
        loading: false,
        finished: false,
        offset: null,
        list: [],
        commentId: null,
      },

      likeUsersLoading: false,
      likeUsers: null,
      viewUsersLoading: false,
      viewUsers: null,
    };
  },
  components: {
    UserIcon,
  },
  mounted() {
    // window.onresize监听页面高度的变化
    window.onresize = () => {
      return (() => {
        this.showHeight = document.body.clientHeight;
      })();
    };
  },
  //监听
  watch: {
    showHeight: function () {
      if (this.docmHeight > this.showHeight) {
        this.keyboarPading = this.docmHeight - this.showHeight + "px";
        this.hideshow = false;
      } else {
        this.keyboarPading = "0px";
        this.hideshow = true;
      }
    },
  },

  created() {
    this.CommentPage = this.$attrs.CommentPage;
    this.init();
    this.onLoad();
  },
  methods: {
    oninputstring() {
      if (this.hideshow) {
        if (this.value.length > 0) {
          this.$refs.commentInput.style.height = "250px";
        } else {
          this.$refs.commentInput.style.height = "50px";
        }
      } else {
        if (this.value.length > 0) {
          this.$refs.commentInput.style.height = "200px";
        } else {
          this.$refs.commentInput.style.height = "50px";
        }
      }
    },
    onCancelinputstring() {
      this.$refs.commentInput.style.height = "50px";

      console.log(arguments);
    },
    toUserHomePage(id) {
        this.$router.push({
          path: "/userHomepage",
          query: { para: id },
        });
    },
    // 提交评论
    async submit() {
      this.$refs.commentInput.style.height = "50px";
      if (!this.value) return false;
      this.submiting = true;
      await this.$sleep();
      try {
        var paradata;
        if (this.reply.commentId) {
          //回复评论
          paradata = {
            itemType: 6,
            detailsText: this.value,
            itemId: this.reply.commentId,
          };
        } else {
          //评论Item本身
          paradata = {
            itemType: this.CommentPage.filterData.itemType,
            detailsText: this.value,
            itemId: this.CommentPage.filterData.itemId,
          };
        }

        const aresult = await AddCommentInfo(paradata);

        if (aresult.data.success) {
          if (
            aresult.data.scoreMessage !== undefined &&
            aresult.data.scoreMessage != ""
          ) {
            Toast({
              message: aresult.data.scoreMessage,
              icon: "/image/signin_point_add.png",
            });
          } else {
            Toast("评论成功");
          }
        }

        this.value = "";
        if (this.reply.commentId) {
          this.showReply = false;
          //显示回复
          this.comments.forEach((element) => {
            if (element.id == this.reply.commentId) {
              element.replyList.unshift(aresult.data.data);
            }
          });

          this.reply.commentId = null;
        } else {
          this.comments.unshift(aresult.data.data);
        }

        // this.comments = [];
        // this.CommentPage.current=1
        // this.onLoad();
      } catch (error) {
        this.$mynotify({ type: "danger", message: "评论失败" });
      }
      this.submiting = false;
    },
    // 打开对话框重置数据
    async openReply(commentId) {
      this.showReply = true;
      this.reply.commentId = commentId;
      this.reply.list = [];
      this.reply.offset = null;
      this.reply.loading = true;
      this.reply.finished = false;
      this.ReplyPage.filterData.itemId = commentId;
      this.ReplyPage.current = 0;
      await this.getReply();
    },
    // 获取文章评论回复
    async getReply() {
      let aresult = await getCommentList({ ...this.ReplyPage });
      this.reply.list.push(...aresult.data.data);
      this.reply.loading = false;
      if (
        this.ReplyPage.current * this.ReplyPage.pageSize <
        aresult.data.total
      ) {
        this.ReplyPage.current++;
      } else {
        this.reply.finished = true;
      }
      this.reply.loading = false;
      this.reply.finished = true;
    },
    // 获取文章评论
    async onLoad() {
      this.loading = true;
      let aresult = await getCommentList({ ...this.CommentPage });

      this.comments.push(...aresult.data.data);
      this.loading = false;

      if (
        this.CommentPage.current * this.CommentPage.pageSize <
        aresult.data.total
      ) {
        this.CommentPage.current++;
        this.finished = false;
      } else {
        this.finished = true;
      }
      //   this.finished = data.last_id === data.end_id;
      //   if (!this.finished) {
      //     this.offset = data.last_id;
      //   }
    },
    async LikeComment(id) {
      let aresult = await UpdateCommentLikeCountInfo({ id: id });
      if (aresult.data.success) {
        if (
          aresult.data.scoreMessage !== undefined &&
          aresult.data.scoreMessage != ""
        ) {
          Toast({
            message: aresult.data.scoreMessage,
            icon: "/image/signin_point_add.png",
          });
        } else {
          Toast("点赞成功");
        }
        this.comments.forEach((element) => {
          if (element.id == id) {
            element.likeCount = element.likeCount + 1;
            element.hasLike = true;
            // Vue.set(element);
          }
        });
      }
    },

    async LikeHandle() {
      const aresult = await AddLike({
        itemType: this.CommentPage.filterData.itemType,
        itemId: this.CommentPage.filterData.itemId,
      });
      if (aresult.data.success) {
        if (
          aresult.data.scoreMessage !== undefined &&
          aresult.data.scoreMessage != ""
        ) {
          Toast({
            message: aresult.data.scoreMessage,
            icon: "/image/signin_point_add.png",
          });
        } else {
          Toast("点赞成功");
        }

        this.isLike = true;
        this.LikeCount++;
      }
    },

    async CollectHandle() {
      const aresult = await AddLike({
        itemType: this.CommentPage.filterData.itemType * 100,
        itemId: this.CommentPage.filterData.itemId,
      });
      if (aresult.data.success) {
        Toast("收藏成功");
        this.isCollect = true;
        this.CollentCount++;
      }
    },

    async init() {
      let aresult = await getItmeLikeList({ ...this.CommentPage.filterData });
      if (aresult.data.data.length > 0) {
        aresult.data.data.forEach((element) => {
          if (element.itemType < 100) {
            this.isLike = element.hasDone;
            this.LikeCount = element.count;
          }
          //收藏
          if (element.itemType >= 100 && element.itemType <= 1000) {
            this.isCollect = element.hasDone;
            this.CollentCount = element.count;
          }
        });
      }
    },

    async getLikeUserList() {
      this.likeUsersLoading = true;
      var d = await getCommentLikeUserList(this.CommentPage.filterData);
      if (d.data.success) {
        this.likeUsers = d.data.data;
      } else {
        this.likeUsers = [];
      }
      this.likeUsersLoading = false;
    },

    async getViewUsersList() {
      this.viewUsersLoading = true;
      var d = await getBrosingLogUserList(this.CommentPage.filterData);
      if (d.data.success) {
        this.viewUsers = d.data.data;
      } else {
        this.viewUsers = [];
      }
      this.viewUsersLoading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.comment-list {
  padding: 0px 10px 20px;
}
.comment {
  margin-top: 10px;
  /deep/ .item {
    display: flex;
    padding: 10px 0;
    .info {
      flex: 1;
      padding-left: 10px;
      .name {
        color: #069;
      }
      .zan {
        vertical-align: middle;
        padding-right: 2px;
      }
      .count {
        vertical-align: middle;
        font-size: 10px;
        color: #666;
      }
      .time {
        color: #666;
      }
      p {
        padding: 5px 0;
        margin: 0;
      }
    }
  }
  /deep/ .van-button:active::before {
    background: transparent;
  }
  /deep/.van-icon-good-job {
    color: red;
  }

  /deep/ .van-icon-star {
    color: red;
  }
}
.reply-container {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 50px;
  width: 100%;
  overflow: auto;
  background: #f5f5f5;
  z-index: 2000;
  .submit {
    font-size: 12px;
    color: #3296fa;
  }
}
.reply_dialog {
  height: 100%;
  max-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  .van-action-sheet__header {
    background: #3296fa;
    color: #fff;
    .van-icon-close {
      color: #fff;
    }
  }
  .van-action-sheet__content {
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 44px;
  }
}

.mydivider {
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  margin: 10px 0;
  color: #969799;
  font-size: 14px;
  line-height: 24px;
  border-color: #ebedf0;
  border-style: solid;
  border-width: 0;
}
.mydivider::before {
  content: "";
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  box-sizing: border-box;
  height: 1px;
  border-color: inherit;
  border-style: inherit;
  border-width: 5px 0 0;
}
</style>
